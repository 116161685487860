import React from 'react';
import { Router } from 'react-router';
import ScrollToTop from '../ScrollToTop';

import {SidebarContainer, SidebarWrapper, SideBtnWrap, SidebarRoute ,Icon, CloseIcon, SidebarMenu, SidebarLink, SideA} from "./SidebarElements";

const SideBar = ({isOpen, toggle}) => {
    return (
        <>
            <ScrollToTop>
                <SidebarContainer isOpen={isOpen} onClick={toggle}>
                    <Icon onClick={toggle}>
                        <CloseIcon />
                    </Icon>
                    <SidebarWrapper>
                        <SidebarMenu>
                            <SideA href="http://shop.efeed.in/login"onClick={toggle}>Log in</SideA>
                            <SideA href="https://www.agrigator.earth/"onClick={toggle}>Agrigators</SideA>
                        </SidebarMenu>
                    </SidebarWrapper>
                </SidebarContainer>
            </ScrollToTop>
        </>
    )
}

export default SideBar;
