import React from 'react'
import {Link} from "react-router-dom";
import {
    FooterContainer,
    FooterWrapper,
    FooterColumn,
    FooterBlock,
    CopyrightWrapper
} from "./FooterElements"; 

const Footer = () => {
    
    return (
        <FooterContainer>
            <FooterWrapper>
                <FooterColumn>
                    <FooterBlock>
                        <h1>Contact us</h1>
                        <h2>support@efeed.in</h2>
                        <h2>+91 9451463635</h2>
                        <h2>+91 9844943862</h2>
                        <h2>+91 9454645154</h2>
                    </FooterBlock>
                </FooterColumn>
                <FooterColumn>
                    <FooterBlock>
                        <h1>Head Office</h1>
                        <h2>15A, 4th Floor City Vista Suite No. 538 <br/> Fountain Road Pune, Maharashtra, 411014, India</h2>
                    </FooterBlock>

                </FooterColumn>
                <FooterColumn>
                    <FooterBlock>
                        <h1>FAQs</h1>
                        <h2><Link to="/privacy" rel="noopener noreferrer" target="_blank">Privacy Policy</Link></h2>
                        <h2>CIN - U01100PN2016PTC165036</h2>
                        <h2>GSTIN - 27AAHCR8069G1ZX</h2>
                    </FooterBlock>
                </FooterColumn>
                <FooterColumn>
                    <FooterBlock>
                        <h1>Follow us</h1>
                        <h2><a href="https://www.facebook.com/agrigatorfishries" rel="noopener noreferrer" target="_blank">Facebook</a></h2>
                        <h2><a href="https://www.instagram.com/agrigator.earth/" rel="noopener noreferrer" target="_blank">Instagram</a></h2>
                        <h2><a href="https://www.youtube.com/channel/UCum97eV0-B0gZbsdqydSxzg" rel="noopener noreferrer" target="_blank">Youtube</a></h2>
                        <h2><a href="https://www.linkedin.com/company/agrigator-earth/" rel="noopener noreferrer" target="_blank">Linkedin</a></h2>
                    </FooterBlock>
                </FooterColumn>
            </FooterWrapper>
            <CopyrightWrapper>
                AgriGators™ Enterprises Pvt. Ltd.
            </CopyrightWrapper>
        </FooterContainer>
    )
}

export default Footer