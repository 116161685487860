import styled from "styled-components";
import {Link as LinkR} from 'react-router-dom';
import {Link as LinkS} from 'react-scroll';
import {FaTimes} from "react-icons/fa";

export const SidebarContainer = styled.aside`
    position: fixed;
    
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #BFDBEA;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    padding: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '90%' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
    padding:0;
`


export const CloseIcon = styled(FaTimes)`
    color: #185B7C;
    font-size: 1.2rem;
    margin: 10px;
`

export const SidebarMenu = styled.ul`
    padding:0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 70px);
    text-align: center;
    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(6,60px);
    }
`
export const Icon = styled.div `
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`

export const SidebarLink = styled(LinkR)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 600;
    list-style: none;
    text-decoration: none;
    transition: 0.2s ease-in-out;
    color: #185B7C;
    cursor: pointer;
   
    &:hover{
        text-decoration: none;
        color: #414B52;
        transition: 0.2s ease-in-out;
    }
`
export const SideA = styled.a `
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 600;
    list-style: none;
    text-decoration: none;
    transition: 0.2s ease-in-out;
    color: #185B7C;
    cursor: pointer;

    &:hover{
        text-decoration: none;
        color: #414B52;
        transition: 0.2s ease-in-out;
    }

`

export const SidebarWrapper = styled.div `
    color: #fff;
`

export const SideBtnWrap = styled.div `
    display: flex;
    justify-content: center;
`

export const SidebarRoute = styled(LinkR) `
    border-radius: 50px;
    color: #185B7C;
    white-space: nowrap;
    padding: 16px 64px;
    color:  #f1f4f5;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
    }
`

