import styled from 'styled-components'


export const ProductContainer = styled.div`
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding-top: 0;
    alignItems: top;
    margin: 0;

`

export const Container = styled.div`
    background: #ffffff;  /* fallback for old browsers */
    height: 600px;
 `
export const ProductText = styled.div`
    h2{
        text-align:center;
        margin: 0 70px;
        font-size: 2rem;
        color: #185B7C;
        font-weight: bold;
        @media screen and (max-width: 1024px){
            text-align: center;
            margin:0;
    }

    }
`