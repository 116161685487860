import React, {useState, useEffect} from 'react'
import Hero from '../components/Hero'
import Navbar from '../components/Navbar'
import SideBar from '../components/Sidebar'
import Footer from '../components/Footer'
import Scrollbars  from 'react-custom-scrollbars'
import ScrollToTop from '../components/ScrollToTop'
import Product from '../components/Product'
import Categories from '../components/Categories'
import './home.css'
import Brand from '../components/Brand'


const Home = ({ deviceType }) =>  {
    const renderThumb = ({ style, ...props }) => {
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: 'rgba(35, 49, 86, 0.8)'
        };
        return <div style={{ ...style, ...thumbStyle }} {...props} />;
    };
    const CustomScrollbars = props => (
        <Scrollbars
          renderThumbHorizontal={renderThumb}
          renderThumbVertical={renderThumb}
          {...props}
        />
      );

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    return (
          <ScrollToTop>
            {/* <div style={mystyle}> */}
                
                <SideBar  isOpen={isOpen} toggle={toggle}/>
                <Navbar toggle={toggle} />
                <Hero />
                <Categories />
                <Product />
                <Brand />
                <Footer />
                
            {/* </div> */}
          </ScrollToTop>
    
    )
}

export default Home
