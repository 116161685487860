import styled from "styled-components";
import Container from 'react-bootstrap/Container';

export const WaveContainer =styled(Container) `
    height:75vh;
    top: 0;
    zIndex: 2;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 768px) {
        height: 90vh;
    }
`