import React from "react"
import CategoryNames from './CategoryNames'

const Categories = ({children}) => {
    return (
        <>
            <CategoryNames />    
        </>
    )
}

export default Categories
