import React, {useState, useEffect} from 'react';
import {FaBars} from 'react-icons/fa';

import {animateScroll as scroll} from 'react-scroll';
import {
    Nav,
    NavbarContainer, 
    NavLogo, 
    NavItem, 
    MobileIcon, 
    NavLinks, 
    NavMenu,
    NavLinkR,
    NavA
} from './NavbarElements';
import {IconContext}  from 'react-icons/lib';
import ScrollToTop from '../ScrollToTop';
import { Router } from 'react-router';


const Navbar = ({toggle}) => {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () =>{
        if(window.scrollY >= 80){
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }
    const toggleHome = () => {
        scroll.scrollToTop();
    };
     
    useEffect(() => {
        window.addEventListener('scroll', changeNav)
        
    }, [])

    
    return (
       <>
            <ScrollToTop> 
                <Nav scrollNav={scrollNav}>
                    <NavbarContainer>
                        <NavLogo to='/' onClick={toggleHome} >
                            {/* <img src={Logo} style={{width: "8%", height: "auto"}} alt="Agrigators Earth" /> */}
                            Efeed
                        </NavLogo>
                        <MobileIcon onClick={toggle}>
                            <FaBars />
                        </MobileIcon>
                        <NavMenu className="justify-content-end" >
                            <NavItem style={{paddingTop: "10px"}}>
                                <NavA href='https://www.agrigator.earth/'>Agrigators</NavA>
                            </NavItem>
                            <NavItem style={{paddingTop: "10px"}}>
                                <NavA href='http://shop.efeed.in/login'>Log in</NavA>
                            </NavItem>
                        </NavMenu>
                    </NavbarContainer>
                </Nav>
            </ScrollToTop>  
       </>
    );
};

export default Navbar;
