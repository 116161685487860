import React from 'react'
import Img from "../../../images/fishing.svg"
import TextTransition, { presets } from "react-text-transition"
import Button from 'react-bootstrap/Button';

import {
    HeroImageContainer,
    TextWrapper,
    AppTitle,
    AppWrapper,
    App
} from "./HeroImageElements";

const TEXTS = [
    "भारत के सर्वोत्तम फीड",
    "न्यूनतम दर",
    "भारी छूट",
    "होम डिलीवरी",
    "विशेषज्ञ सहायता"
];

const HeroImage = () => {
    const [index, setIndex] = React.useState(0);
    React.useEffect(() => {
        const intervalId = setInterval(() =>
        setIndex(index => index + 1),
        3000 // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);
    return (
        <>
            <HeroImageContainer>
                <AppWrapper>
                    <App src={Img} alt="" />
                </AppWrapper>
                <TextWrapper>
                    <AppTitle>
                        <h1>
                            1 लाख से ज्यादा मछली पालकों का भरोसा 
                        </h1>
                        
                    </AppTitle>
                    <AppTitle>
                        <h2>
                            <TextTransition
                                text={ TEXTS[index % TEXTS.length] }
                                springConfig={ presets.wobbly }
                                style={{margin:"0", padding:"0", height: "3rem"}}
                            />
                        </h2>
                    </AppTitle>
                    <Button href='http://shop.efeed.in/login' variant="primary"  style={{background: "#185B7C", maxHeight: "50px", maxWidth: "150px"}}>
                        <p style={{ fontWeight: "500", letterSpacing: "1px", marginTop: "5px"}}>
                        Log in
                        </p>
                    </Button>
                </TextWrapper>
            </HeroImageContainer>
        </>
    )
}

export default HeroImage