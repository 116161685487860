import React from 'react'
import Footer from '../Footer';
import { PrivacyBlob } from '../Hero/Blob/Blobs';


import {
    MainContainer,
    Wrapper,
    SubHeader,
    SubText,
    SubList,
    ListItem, 
    Header
} from "./PrivacyElements";

const Privacy = () => {
    return (
        <MainContainer>
            <PrivacyBlob />
            <Wrapper>
                <Header>Privacy Policy</Header>
                <SubText>
                AgriGators™ understands the value of your precious data and respects your privacy & confidentiality of your data with extreme priority and concern. We at AgriGators™, are committed to protecting the privacy and security of your personal information. Your privacy is most important to us and maintaining your trust is paramount to us. This Privacy Policy is meant to help you understand what information we collect, why we collect it, and how you can delete your information. What we are building :<br /><br/><b> We aim to offer a range of services to our target audience (farmers) and the services include –</b>
                </SubText>
                <SubList>
                    <ListItem>A platform for fish farmers of India</ListItem>
                    <SubText>We aim to create a social platform for fish farmers of INDIA to connect with the experts and get to know about the best fish farming practices by their fellow farmers and Experts. App users can perform activities including like, comment, Facebook share and WhatsApp share.Users can also find and invite fellow farmers on the app to grow their network.</SubText>
                    <ListItem>Information about market rates of various Fishes</ListItem>
                    <SubText>Majority of the farmers are dependent on local traders to sell their produce and often earn lesser margins due to lack of visibility on the market rates. We aim to increase transparency of the same to help farmers negotiate better terms for their produce</SubText>
                    <ListItem>Knowledge about various Farming Practices in India along with their region based cropping cycle</ListItem>
                    <SubText>We aim to offer the most reliable information to a farmer for the Agri Business Models  in his region.</SubText>
                    <ListItem>Information about Sellers of various farm inputs</ListItem>
                    <SubText>To improve the discovery of sellers and better farm inputs</SubText>
                    <ListItem>Guidance and support:</ListItem>
                    <SubText>We aim to offer you our best support and guidance in the field of fish farming and provide you guidance for better productivity/profitability. We do hereby clarify that we will or any of the publishers on the app shall not be held liable for any damages direct or indirect caused to you while using or relying on the content published on this application. Any action taken by the user as per the answers/post/opinions received on the platform is based on their own discretion. Users are advised to fact check the opinions of the publishers on the platform. Informations we collect when you sign up on our app Name, Phone number, Geolocation Informations you provide to us as you use our services – Your photo to enrich the profile for other users to be able to identify you. You are free to leave that field blank, in that case your user profile will have a generic mascot photo created by us. Photos of your Fishes – You may wish to upload photos of your fishes in order to identify the disease in your fishes and get resolution for the same. We will use the photos uploaded by you to train our deep learning model in future for better accuracy in the disease detection process</SubText>
                    <SubHeader>Information we collect as you use our services :</SubHeader>
                    <SubList>
                        <ListItem>Device level information</ListItem>
                        <SubText>We track your device info to understand if the app is running smoothly on all devices. We collect information like IP address, device’s name, device’s serial number or unique identification number (e.g. UDiD on Your iOS device), device operating system, browser type and version, CPU speed, and connection speed etc.</SubText>
                        <ListItem>Activities</ListItem>
                        <SubText>We track all activities you do on the app – like articles you read, videos you watch, profiles you visit, screens you open etc. to understand the users behaviour on the platform. We also read the list of installed app on the device, which helps us show you the most relevant ads and send you personalised offers. If you are logged in, your activity data is mapped with your user id. If you are not logged in, a unique instance id will be generated for you and all your activity data will be mapped with the same.</SubText>
                    </SubList>
                </SubList>
            </Wrapper>
            <Wrapper>
                <SubHeader>Permissions we require from you to offer better services: </SubHeader>
                <SubList>
                    <ListItem>SMS reading access</ListItem>
                    <SubText>We will only read you messages from VM-KRISHI (or equivalent) and will not read or store any of your other messages.</SubText>
                    <ListItem>Camera</ListItem>
                    <SubText>Camera access is required to use one of our services – Disease detection . You need to click a photo of your fish to get the disease identification and its cure</SubText>
                    <ListItem>Contact list</ListItem>
                    <SubText>Give us the permission to read your contact list in order to find friends for you on Agrigators™ App. This will help you grow your network on the platform. Your contact list data will not be shared directly or indirectly with any 3rd party individual or organisation without the consent from the user.</SubText>
                    <ListItem>Geolocation</ListItem>
                    <SubText>We need to track your geo-location for market rates updates and to show the content on the app relevant to your region</SubText>
                    <ListItem>Get accounts</ListItem>
                    <SubText>Allow us this permission to fetch your email id. Your email id will not be shared with any 3rd party individual or organisation except Facebook audience without taking your prior consent. This can be used to create a lookalike audience on Facebook to run ads for Agrigators™ App.</SubText>
                    <ListItem>Photos/Gallery</ListItem>
                    <SubText>We need this permission for one of the services – Disease detection. We create a folder in your gallery app with the name Agrigators™ to keep all the photos uploaded by you on the app. The app will fetch photos from this folder only to list them and show you on the app under your profile only.</SubText>
                    <ListItem>Information Others Provide About you</ListItem>
                    <SubText>We receive information other people provide us, which may include information about you. For example, when other users you know use our Services, they may provide your phone number from their mobile address book (just as you may provide theirs), or they may send you a message, send messages to groups to which you belong, or call you. How will your precious data be used</SubText>
                    <SubList>
                        <ListItem>To provide the best services</ListItem>
                        <SubText>The information provided by you help us deliver our best services to you. For example, photos uploaded by you help us identify the fish disease and offer a cure. Your geo location data help us provide you market rates information</SubText>
                        <ListItem>To improve the quality of our services</ListItem>
                        <SubText>The activities data we track help us understand which part of this app is not very user friendly or if there are any kind of bugs or issues in the app basis which we update or modify our services.</SubText>
                        <ListItem>To help you connect with experts on the platform</ListItem>
                        <SubText>We believe in learning from community concept. Having a network of progressive experts you can see or get to know their activities on the platform, can comment on their posts, etc. To build this network strong we track your contact list and bring more farmers on to the app.</SubText>
                        <ListItem>To communicate with you</ListItem>
                        <SubText>To send occasional SMS in case of a new feature release or other important update. We also send you push notification on the app if their is any new content or activity relevant to you. We may also send you emails in case there is any important update.</SubText>
                        <ListItem>To show you personalised ads and get you the best offers/deals</ListItem>
                        <SubText>We read the list of apps installed on your device for delivering the most relevant ads and offers to you. We share your contact number with the sellers of the products that you show interest in. <br /> <br /><b> Who else may have access to your data </b> <br />We use multiple tools’ SDKs to understand the users behaviour on the app, to serve ads on the platform and to run optimised ad campaigns. These tools may have access to your activities and profile data fully or partially, depending on the nature of the tool and their use cases. These tools include –
                            <SubList>
                                <ListItem>Facebook Analytics</ListItem>
                                <ListItem>Facebook Ads</ListItem>
                                <ListItem>Firebase Analytics</ListItem>
                                <ListItem>Google Adsense</ListItem>
                                <ListItem>Google Adwords</ListItem>
                            </SubList>
                            All the 3rd parties are prohibited from using your personal information except to provide services to us and they are required to maintain the confidentiality of your information. We hereby declare that we will provide your information to the authorities, department or people if it is required by law.</SubText>
                        <ListItem>Removing Profile</ListItem>
                        <SubText>For getting your profile removed, send us an email at support@agrigator.earth with ‘Delete my profile’ as subject and mentioning your phone number in the email body. You get this option on the app. You may choose to Delete your profile. This action will mark your profile as deleted in our system and will log you out from the app. This may take upto 48 hours to reflect in the system.</SubText>
                        <ListItem>After removing your profile-</ListItem>
                            <SubList>
                                <ListItem>You will not receive any communication from us on email or SMS.</ListItem>
                                <ListItem>You may continue to receive push notification if you keep the app installed on your device.</ListItem>
                                <ListItem> Your posts and comments remain in the system but your name will be changed with a random user name (created by our system which may or may not be unique) and your profile picture will be changed with an icon created by us. So any past post or comment done by you will be discoverable on the platform but without your name and profile photo.</ListItem>
                                <ListItem>Other users will not be able to follow you on the platform.</ListItem>
                                <ListItem>You many choose to restore your account by writing at support@agrigator.earth with subject as “Restore account”. You will need to mention your earlier registered phone number with your request in the email.</ListItem>
                
                            </SubList>
                        <ListItem>Deleting Data from ™</ListItem>
                        <SubText>For getting your data deleted, send us an email at support@agrigator.earth with ‘Delete my data’ as subject and mentioning your phone number in the email body. This action may take upto 7 working days to come into effect.</SubText>
                        <SubText><b>After deleting your profile data –</b></SubText>
                        <SubList>
                            <ListItem>All your profile info will be deleted from our system</ListItem>
                            <ListItem>Any activity data mapped with your user id will be deleted from our system</ListItem>
                            <ListItem>Some of your profile data and activity data are shared with 3rd party analytics tool which may not be deleted even after deleting permanently from our system.</ListItem>
                            <ListItem>There will be no option to restore your account after deleting.</ListItem>
                            <ListItem>You may choose to sign up again with the same phone number after deleting your account.</ListItem>
                            <ListItem>You will not be barred from any services offered by AgriGators™ but your past data will be lost.</ListItem>
                        </SubList>
                        <ListItem>Cookies</ListItem>
                        <SubText>To improve the responsiveness of the Platform, make the experience more seamless or enhance the effectiveness of our services, we may use “cookies”, or similar electronic tools to collect Information.</SubText>
                        <ListItem>Changes to Privacy Policy</ListItem>
                        <SubText>We may update and/ or modify this Policy from time to time to incorporate necessary changes in technology, applicable law, or any other variant. In any case, we reserve the right to change (at any point of time) the terms of this Policy or the Terms of Use. After changes take place, we do our best to inform you and due to any unavoidable reason we cannot, it’s your responsibility to refer to the policy periodically.</SubText>
                        <ListItem>Limitation of Liability</ListItem>
                        <SubText>We respect the privacy of our user and we maintain a secure system. But we will not be liable for any damages, direct or indirect, incidental or consequential, and any liabilities including for negligence, loss or damage, such as loss of revenue, unavailability of system or loss of data and loss of your Personal Information and for use of or inability to use Services of any kind that is offered or to provide indemnity or any other remedy to you or any third party.</SubText>
                        <ListItem>Contact Us</ListItem>
                        <SubText>If you have questions, feedback or concerns or grievances regarding this Privacy Policy, you can contact us, or if you wish to make a complaint, please contact us at as support@agrigator.earth. If you want to report any abuse, infringement please contact us at 9451463635.</SubText>
                    </SubList>
                </SubList>
            </Wrapper>
        </MainContainer>
        
    )
}

export default Privacy