import React from 'react'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { ObjFive, ObjFour, ObjOne, ObjThree, ObjTwo } from './ProductCard/data'
import ProductCard from './ProductCard'
import {Container, ProductText} from './ProductElements'

const Product = () => {
    
    return (
        <>

            <Container>
            <ProductText><h2>हमारे उत्पाद</h2></ProductText>
                <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="container-with-dots"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 5,
                        partialVisibilityGutter: 40
                        },
                        mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                        },
                        tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                        }
                    }}
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                    >
                    <ProductCard {... ObjOne} />
                    <ProductCard {... ObjTwo} />
                    <ProductCard {... ObjThree} />
                    <ProductCard {... ObjFour} />
                    <ProductCard {... ObjFive} />
                </Carousel>
            </Container>
        </>
    )
}

export default Product




