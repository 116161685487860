import styled from 'styled-components'

export const MainContainer = styled.div`
    background: #ffffff;
    height: 145vh;
    margin: 20px;
    
    @media screen and (max-width: 768px) {
        height: 250vh;
        margin-top: 30px;
    }
    @media screen and (max-width: 460px) {
        height: 300vh;
        margin-top: 30px;
    }
`
export const Circle = styled.div`
    background: lightblue;
    border-radius: 50%;
    width: 230px;
    height: 250px;
    z-index: 5;
    align-items: center;
    display: block;
    margin-left: auto; 
    margin-right: auto;
`
export const ImageElement = styled.div`
    z-index: 10;

`
export const BrandText = styled.div`
    h2{
        text-align: center;
        margin: 0 70px;
        font-size: 2rem;
        color: #185B7C;
        font-weight: bold;
        @media screen and (max-width: 1024px){
            text-align: center;
            margin:0;
    }

    }
`