import React from 'react'
import HeroImage from "./HeroImage"
import {WaveContainer} from './HeroElements'


const Hero = () => {
    return (
        <>
            <WaveContainer>
                <HeroImage />
            </WaveContainer>
           
        </>
    )
}

export default Hero