import React from 'react'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Image } from "semantic-ui-react"
import {ProductContainer}  from "../ProductElements"

const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
});

const styles =  makeStyles({
    root: {
        justifyContent: 'center'
    },
    
});

const ProductCard =  ({ headLine, description, img}) => {
   
    const classes = useStyles();
    return (
        <>
            <ProductContainer>
                <Card className={classes.root}>
                    <CardActionArea>
                        <Image
                        style={{ width: "180px", height: "210px" }}
                        src={img}
                        />
                        <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" align="center">
                            {headLine}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                        {description}
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions classes={{root: classes.root}} style={{justifyContent: 'center'}}>
                        <Button size="small" color="primary" align="center" href="https://shop.efeed.in/login">
                        Learn More
                        </Button>
                    </CardActions>
                </Card> 
            </ProductContainer> 
        </>
    )
}

export default ProductCard
