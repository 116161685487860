import React, {useState} from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Privacy from '../components/Privacy'
import SideBar from '../components/Sidebar'


const PrivacyPage = () => {
    const renderThumb = ({ style, ...props }) => {
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: 'rgba(35, 49, 86, 0.8)'
        };
        return <div style={{ ...style, ...thumbStyle }} {...props} />;
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    const mystyle = {
        background: 'none'
      };
    return (
        <div style={mystyle}>
            <SideBar  isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle} />
            <Privacy />
            <Footer />
        </div>
    )
}

export default PrivacyPage
