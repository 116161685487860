import React from "react"
import BrandImages from './BrandImages'

const Brand = ({children}) => {
    return (
        <>
            <BrandImages />    
        </>
    )
}

export default Brand
