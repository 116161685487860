import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import {MainContainer, BrandText, Brackets, Button} from './CategoryNamesElements'


const CategoryNames = () => {
    return (
        <MainContainer>
        <BrandText><h2>उत्पाद श्रेणी</h2></BrandText>
            <Container >
                <Row>
                    <Col lg={4} md={6} sm={12} style={{display:"block", width: "auto", marginLeft:"auto", marginRight:"auto", marginTop:"45px", marginBottom:"45px", display:"block" , alignItems:"center",jusifyContent:"center"}}>
                         <Brackets style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                            <Button style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                मत्स्य फीड 
                            </Button>
                         </Brackets>                   
                    </Col>      
                    <Col lg={4} md={6} sm={12} style={{width: "auto", marginLeft:"auto", marginRight:"auto", marginTop:"45px", marginBottom:"15px", display:"block" ,jusifyContent:"center"}}>
                    <Brackets style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                            <Button style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                मत्स्य बीज     
                            </Button>
                         </Brackets>              
                    </Col>  

                    <Col lg={4} md={6} sm={12} style={{width: "auto", marginLeft:"auto", marginRight:"auto", marginTop:"45px", marginBottom:"15px", display:"block" ,jusifyContent:"center"}}>
                        <Brackets style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                            <Button style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                दवाइयां 
                            </Button>
                         </Brackets>              
                    </Col>  

                    <Col lg={4} md={6} sm={12} style={{width: "auto", marginLeft:"auto", marginRight:"auto", marginTop:"45px", marginBottom:"15px", display:"block" ,jusifyContent:"center"}}>
                        <Brackets style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                            <Button style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                बायोफ्लोक 
                            </Button>
                         </Brackets>               
                    </Col>  

                    <Col lg={4} md={6} sm={12} style={{width: "auto", marginLeft:"auto", marginRight:"auto", marginTop:"45px", marginBottom:"15px", display:"block" ,jusifyContent:"center"}}>
                    <Brackets style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                            <Button style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                            श्रेष्ठ प्रोडक्ट 
                            </Button>
                         </Brackets>            
                    </Col>  
                   
                </Row>
            </Container>
        </MainContainer>
    )
}

export default CategoryNames
