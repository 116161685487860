import { useState, useEffect } from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './pages/Home';
import './App.css'
import PrivacyPage from './pages/PrivacyPage';
import ReactGA from 'react-ga';
ReactGA.initialize('G-CTQ0WR8F2G'); // google analytics tracking id
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <Router>
      <Switch>
          <Route path="/" component ={Home} exact />
          <Route path="/privacy" component={PrivacyPage} exact />
      </Switch>
    </Router>
  )
}

export default App;
