import React from 'react'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import {Circle, MainContainer, BrandText} from './BrandImageElements'


import image1 from '../../images/brands/DAYALPRODUCT.png';
import image2 from '../../images/brands/GODREJPRODUCT.png';
import image3 from '../../images/brands/ABISPRODUCT.png';
import image4 from '../../images/brands/GROWELCATALOGPICTURE.png';
import image5 from '../../images/brands/ANMOLPRODUCT.png';

const BrandImages = () => {
    return (
        <MainContainer>
        <BrandText><h2>कंपनियां</h2></BrandText>
            <Container >
                <Row>
                    <Col lg={4} md={6} sm={12} style={{display:"block", width: "auto", marginLeft:"auto", marginRight:"auto", marginTop:"45px", marginBottom:"45px", display:"block" , alignItems:"center",jusifyContent:"center"}}>
                        <Circle>
                            <Image src={image1} style={{display:"block", marginLeft:"auto", marginRight:"auto", height:'200px', zindex:'10'}} className="d-block mx-auto img-fluid w-75 block-example" />
                            <h3 style={{textAlign:"center",color:"#185B7C", fontSize:"1.5rem"}}>Dayal</h3>
                        </Circle>
                    </Col>      
                    <Col lg={4} md={6} sm={12} style={{width: "auto", marginLeft:"auto", marginRight:"auto", marginTop:"45px", marginBottom:"15px", display:"block" ,jusifyContent:"center"}}>
                        <Circle>
                            <Image src={image2} style={{display:"block", marginLeft:"auto", marginRight:"auto", height:'200px', zindex:'10'}} className="d-block mx-auto img-fluid w-75 block-example" />
                            <h3 style={{textAlign:"center",color:"#185B7C", fontSize:"1.5rem"}}>Godrej</h3>

                        </Circle>
                    </Col>  

                    <Col lg={4} md={6} sm={12} style={{width: "auto", marginLeft:"auto", marginRight:"auto", marginTop:"45px", marginBottom:"15px", display:"block" ,jusifyContent:"center"}}>
                        <Circle>
                            <Image src={image3} style={{display:"block", marginLeft:"auto", marginRight:"auto",height:'200px', zindex:'10'}} className="d-block mx-auto img-fluid w-75 block-example" />
                            <h3 style={{textAlign:"center",color:"#185B7C", fontSize:"1.5rem"}}>Abis</h3>
                        </Circle>
                    </Col>  

                    <Col lg={4} md={6} sm={12} style={{width: "auto", marginLeft:"auto", marginRight:"auto", marginTop:"45px", marginBottom:"15px", display:"block" ,jusifyContent:"center"}}>
                        <Circle>
                            <Image src={image4} style={{display:"block", marginLeft:"auto", marginRight:"auto", height:'200px', zindex:'10'}} className="d-block mx-auto img-fluid w-75 block-example" />
                            <h3 style={{textAlign:"center",color:"#185B7C", fontSize:"1.5rem"}}>Growel</h3>
                        </Circle>
                    </Col>  

                    <Col lg={4} md={6} sm={12} style={{width: "auto", marginLeft:"auto", marginRight:"auto", marginTop:"45px", marginBottom:"15px", display:"block" ,jusifyContent:"center"}}>
                        <Circle>
                            <Image src={image5} style={{display:"block", marginLeft:"auto", marginRight:"auto", height:'200px', zindex:'10'}} className="d-block mx-auto img-fluid w-75 block-example" />
                            <h3 style={{textAlign:"center",color:"#185B7C", fontSize:"1.5rem"}}>Anmol</h3>
                        </Circle>
                    </Col>  
                   
                </Row>
            </Container>
        </MainContainer>
    )
}

export default BrandImages
