import styled from 'styled-components'



export const MainContainer = styled.div`
    background: #ffffff;
    height: 80vh;
    margin: 20px;
    
    
    @media screen and (max-width: 768px) {
        height: 120vh;
        margin-top: 30px;
    }
    @media screen and (max-width: 460px) {
        height: 150vh;
        margin-top: 30px;
    }

`


export const BrandText = styled.div`
    h2{
        text-align: center;
        margin: 0 70px;
        font-size: 2rem;
        color: #185B7C;
        font-weight: bold;
        @media screen and (max-width: 1024px){
            text-align: center;
            margin:0;
    }

    }
`


export const Brackets = styled.div`
    width: 200px;
    height: 70px;
    box-shadow: inset 0px 0px 0px 5px black;
    color: red;
    background: white;
    position: relative;
    border-color: white;
`

export const Button = styled.button`
    height: 70px;
    width: 80%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    background: white;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 1.5px;
    color: #185B7C;
`