import styled, {keyframes} from "styled-components";

export const HeroImageContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 150px;
    @media screen and (max-width: 768px) and (max-width: 1024px){
        flex-direction: column;
        justify-content: center;
        ${'' /* background: #C3E0E5; */}
        background: none;
    }
    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
    }
`

const float = keyframes`
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 10px); }
    to   { transform: translate(0, -0px); } 
`;

export const AppWrapper = styled.div`
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0.8;
    ${'' /* animation: ${float} 3s ease-in-out infinite;
     */}
    @media screen and (max-width: 768px) {
        margin-bottom: 14px;
        height:100%;
    }
`

export const App = styled.img`
    max-width: auto;
    height: 120%;
    @media screen and (max-width: 768px) {
        max-width: 80%;
        height:auto;
        margin-top: 0;
    }
    @media screen and (max-width: 1024px) 
    {
        max-width: 80%;
        height: auto;
        margin-top: 0;
    }
`

export const TextWrapper = styled.div`
    display: flex;
    margin-bottom: 36px;
    flex-direction: column;
    justify-content: center;
    margin-left: 50px;
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        margin-left: 0;
    }
`
export const AppTitle = styled.div`
    h1{
        color: #414B52;
        font-size: 3rem;
        text-align: left;
        line-height: 150%;
        
        font-weight: bold;
        ${'' /* margin-bottom: 50px; */}
        @media screen and (max-width: 768px) {
            font-size: 2rem;
            line-height: 36px;
            text-align: center;
            margin: 0;
        }
        @media screen and (max-width: 1024px) {
            font-size: 2.2rem;
            line-height: 48px;
        }
    }

    h2{
        color: #414B52;
        font-size: 2.5rem;
        text-align: left;
        line-height: 50px;
        
        margin-bottom: 30px;
        margin-top: 10px;
        @media screen and (max-width: 768px) {
            font-size: 1.5rem;
            line-height: 36px;
            text-align: center;
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
        @media screen and (max-width: 1024px) {
            font-size: 2.2rem;
            line-height: 48px;
        }

    }

    h3{
        color: #d8e6f1;
        font-size: 1.8rem;
        text-align: left;
        line-height: 50px;
        margin-left: 30px;
        padding: 10px;
        border-radius: 20px;
        @media screen and (max-width: 768px) {
            font-size: 1.5rem;
            line-height: 36px;
            text-align: center;
            margin: 0;
        }
        @media screen and (max-width: 1024px) {
            font-size: 1.5rem;
            line-height: 48px;
        }

    }
    p{
        margin-left: 35px;
    }
    a{
        
        color: #0f4c75;
    }
    
`

export const AppPara = styled.p`
    color: #414B52;
    font-size: 1.3rem;
    ${'' /* text-align: center; */}
    line-height: 0px;
    @media screen and (max-width: 1024px) {
        align-items: center;
        margin-left: -55px;
    }
    @media screen and (max-width: 768px) {
        font-size: 0.9rem;
        line-height: 36px;
        margin-left: 0px;
        
    }
`

export const AppStore = styled.div`
  width: 50%;
  vertical-align: left;
  @media screen and (max-width: 768px) {
    vertical-align: center;
    ${'' /* margin-left: auto; */}
    margin-right: -40px;
    align-items: center;
    align-self: center;

  }
`;
