export const ObjOne = {
    headLine: 'Abis Grower', 
    description: '', 
    img: require('../../../images/products/Abis_Grower.jpg').default,

}

export const ObjTwo = {
    headLine: 'Abis Fisher', 
    description: '', 

    img: require('../../../images/products/Abis_Fisher.jpg').default,

}

export const ObjThree = {
    headLine: 'Abis Hatchery', 
    description: '', 

    img: require('../../../images/products/Abis_Hatchery.jpg').default,

}

export const ObjFour = {
    headLine: 'Abis Brooder', 
    description: '',  

    img: require('../../../images/products/Abis_Brooder.jpg').default,

}

export const ObjFive = {
    headLine: 'Abis Sinking', 

    img: require('../../../images/products/Abis_Sinking.jpg').default,

}
